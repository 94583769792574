import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import NewCard from "components/LandingPage/modules/components/NewCard";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Paper from '@material-ui/core/Paper';
    

const cards = [
    {
      text: 'Soft flour bun',
      img: 'https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbun1.png?alt=media&token=f5d4ac2b-742b-4e82-aabc-6dcaf5562982',
    },
    {
      text: 'Gluten-free bun',
      img: 'https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbuns1.png?alt=media&token=dbc4eeab-dc35-4999-98db-43acd5e8261a',
    },
    {
      text: 'Lettuce wrap',
      img: 'https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbun3%20.png?alt=media&token=ee1dd194-58b5-4871-8a66-9040ef455b1d',
    },
  ]

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    root2: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        display: 'flex',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        position: 'relative',
    },
      item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5),
      },
      image: {
        height: '100%',
        width: 300,
      },
      title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
      },
  }));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function NavTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab label="Menu" href="/drafts" {...a11yProps(0)} />
          <LinkTab label="Customisation" href="/trash" {...a11yProps(1)} />
          <LinkTab label="Allergen Info" href="/spam" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      Prices below may vary when using our delivery service. All items are subject to availability.
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab label="Burgers" {...a11yProps(0)} />
          <LinkTab label="Sides" />
          <LinkTab label="Sauces" {...a11yProps(2)} />
          <LinkTab label="Kid Meals" {...a11yProps(3)} />
          <LinkTab label="Ice Cream & Drinks" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      Prices below may vary when using our delivery service. All items are subject to availability.
        <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fmenu.png?alt=media&token=4211465d-6985-451b-9cab-2713dfbb3335"
          alt="some pic" width="100%" height="100%"
          />
            </TabPanel>
        <TabPanel value={value}>
      Prices below may vary when using our delivery service. All items are subject to availability.
        <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fmenu.png?alt=media&token=4211465d-6985-451b-9cab-2713dfbb3335"
          alt="some pic" width="100%" height="100%"
          />
            </TabPanel>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Customisation Details
        <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Buns</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Container className={classes.container} >
        <Grid 
          container 
          spacing={4}
          direction="row"
          justify="center"
        >
          {cards.map(card => (          
          <Grid item xs={12} md={4}>
           <NewCard 
            text={card.text}
            img={card.img}
            />
          </Grid>
          ))}
        </Grid>
      </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Patties</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Cheese</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Paper className={classes.root}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Item One" />
        <Tab label="Item Two" />
        <Tab label="Item Three" />
      </Tabs>
    </Paper>
    </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        All the ingredients in the restaurant are plant-based (vegan)
        <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fneat%20allergen.png?alt=media&token=dcbdff9b-0aa6-448d-8d17-dbe53054ecda"
          alt="some pic" width="100%" height="100%"
          />
        <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fneat%20allergen2.png?alt=media&token=65e94f46-9cb0-40df-b4b4-ac23d0931b7a"
          alt="some pic" width="100%" height="100%"
          />
        <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fneat%20allergen3.png?alt=media&token=0ae7a712-d484-423c-8081-0ee96f50c593"
          alt="some pic" width="100%" height="100%"
          />
        <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fneat%20allergen4.png?alt=media&token=47620ee6-f464-4535-aa8a-307b377a206f"
          alt="some pic" width="100%" height="100%"
          />
        <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fneat%20allergen5.png?alt=media&token=0d4977dd-b230-48e4-a550-f57b71ff3bd6"
          alt="some pic" width="100%" height="100%"
          />
      </TabPanel>
    </div>
  );
}
