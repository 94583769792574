import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React, { useEffect } from "react";
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import ProductValuesCopy from './modules/views/ProductValuesCopy';
import Fade from './modules/views/Fade';
import Grid from '@material-ui/core/Grid';
import Valueskeks from './modules/views/Valueskeks';
import Times from './modules/views/Times';
import Burger from './modules/views/Burger';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import DragnDrop from './modules/views/DragnDrop';
import Dragn from './modules/views/Dragn';
import DragTwo from './modules/views/DragTwo';
import Dragging from './modules/views/DragBurger/Dragging';


function FadeInWhenVisible({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0.5, scale: 0.5 }
      }}
    >
      {children}
    </motion.div>
  );
}
 
function LandingHome() {

  return (
    <React.Fragment>
      <div>
        <ProductHero />
      <FadeInWhenVisible>
        <ProductValues />
        </FadeInWhenVisible>
            <div background-size="auto"
  background-repeat="no-repeat"
  background-position="center"
      style={{
         backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FLeaves4.jpeg?alt=media&token=f53a613c-c9d2-4c10-9d0a-24564493dcf0")`
      }}>
        <FadeInWhenVisible>
      <ProductCategories />
      </FadeInWhenVisible>
      </div>
        <FadeInWhenVisible>
         <ProductHowItWorks />
      </FadeInWhenVisible>
      <FadeInWhenVisible>
      <Fade />
      </FadeInWhenVisible>
      <FadeInWhenVisible>
      <ProductValuesCopy />
      </FadeInWhenVisible>
      <div background-size="auto"
  background-repeat="no-repeat"
  background-position="center"
      style={{
         backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FLeaves4.jpeg?alt=media&token=f53a613c-c9d2-4c10-9d0a-24564493dcf0")`
      }}>
      <Times />
      </div>
      <FadeInWhenVisible>
      <Valueskeks />
      </FadeInWhenVisible>
      <FadeInWhenVisible>
      <ProductSmokingHero />
      </FadeInWhenVisible>
      <AppFooter />
      </div>
    </React.Fragment>
  );
}

export default withRoot(LandingHome);