import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  media: {
    height: '100%',
    width: '100%',
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(0),
  },
  link: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buoy: {
    marginTop: theme.spacing(1),
    width: 100,
  },
  cardRoot: {
    width: '100%',
  },
  media: {
    height: 400,
    border: "none",
    boxShadow: "none",
    borderColor: "white",
  },
  logo: {
    fontSize: 36,
    color: '#FFBF00',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
  span: {
    marginTop: 1,
  }
}));

function ProductSmokingHero() {

  const classes = useStyles();



  var settings = {
    infinite: true,
    autoplaySpeed: 4000,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    fade: true,
  };
  return (
    <Container className={classes.root} component="section">

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} md={3}>
        <Slider {...settings}>
      <div>
          <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fgirl4.png?alt=media&token=a63a131f-030b-4cdd-8323-670c8c0c7ab6"
          align="left" width="250" height="400"
          />
         </div>
        <div>
        <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3bf8.jpeg?alt=media&token=866366e5-ccb7-4e07-85bf-8fe7fb7e4962"
          align="center" width="200" height="100%"
          />
        </div>
     </Slider>   
        </Grid>
        <Grid item xs={12} md={5}>
        <Typography variant="h4" marginTop="1" marginBottom="4" marked="center" align="center" component="h2" >
          Our Story
        </Typography>
        <Typography variant="subtitle1" marginTop="3" align='center'>
         .  .  .
        </Typography>
        <Typography variant="subtitle1" marginTop="3" align='center' paragraph>
      We are Anna and Madina. Being vegan for 4 years, we came 
      across some nuisances when ordering food and eating out.
      </Typography>
      <Typography variant="subtitle1" align='center' paragraph>
      In 2021, some UK restaurants still don’t offer vegan options
      or correct labelling, underperforming their customer service.
      </Typography>
      <Typography variant="subtitle1" align='center' paragraph>
      Delivery companies don’t suggest a correct vegan search,
      limiting users' options and primarily promoting fast-food.
      </Typography>
      <Typography variant="subtitle1" align='center' paragraph>
      An authentic, vegan-friendly & health-promoting platform 
      is what could make a positive impact on people’s food habits.
      </Typography>
      <Typography variant="subtitle1" align='center' paragraph>
      Stay positive and create your own food with love!
      </Typography>
      <Typography variant="subtitle1" align="center" className={classes.logo}>
          MyMeal
       </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
        <Slider {...settings}>
        <div>
          <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c60.jpeg?alt=media&token=9a4fd406-f011-49f7-94d7-13169818bf91"
          align="right" width="240" height="400"
          />
         </div>
        <div>
        <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c62.jpeg?alt=media&token=151e3e42-ccc5-4932-a249-9dda51842b0c"
          align="right" width="230" height="100%"
          />
         </div>
     </Slider>   
        </Grid>
        
        <Grid item xs={12} md={4}>
        <Button className={classes.button} fullWidth href='/aboutus'>
          <Typography variant="h5" align="center" component="span">
            Learn more about us
          </Typography>
        </Button>
        <Typography variant="subtitle1" align="center" className={classes.link}>
          Need help? Get in touch!
        </Typography>
        </Grid>
      </Grid>


      

    </Container>
  );
}

function MediaCard(props) {
  const classes = useStyles();

  const {url} = props;

  return (
    <Card>
        <CardMedia
          className={classes.media}
          image={url}
          title="Contemplative Reptile"
        />
    </Card>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default ProductSmokingHero;