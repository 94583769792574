import React, { Component } from "react";
import PropTypes from "prop-types";
import validate from "validate.js";
import { withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Paper,
  Avatar,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  Link,
  Container,
} from "@material-ui/core";
import constraints from "../../data/constraints";
import authentication from "../../services/authentication";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Redirect, Link as RouterLink } from "react-router-dom";


const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/examples%2Fsignup.jpg?alt=media&token=2956abfd-55aa-496f-801d-c943e304054a)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(1),
  },
  mainText: {
    color: 'white',
    fontWeight: 'bold',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});


const initialState = {
  performingAction: false,
  emailAddress: "",
  emailAddressConfirmation: "",
  password: "",
  passwordConfirmation: "",
  errors: null,
};

class SignUpDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  signUp = () => {
    const {
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation,
    } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress,
        emailAddressConfirmation: emailAddressConfirmation,
        password: password,
        passwordConfirmation: passwordConfirmation,
      },
      {
        emailAddress: constraints.emailAddress,
        emailAddressConfirmation: constraints.emailAddressConfirmation,
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
          errors: null,
        },
        () => {
          authentication
            .signUpWithEmailAddressAndPassword(emailAddress, password)
            .then((value) => {})
            .catch((reason) => {
              const code = reason.code;
              const message = reason.message;

              switch (code) {
                case "auth/email-already-in-use":
                case "auth/invalid-email":
                case "auth/operation-not-allowed":
                case "auth/weak-password":
                  this.props.openSnackbar(message);
                  return;

                default:
                  this.props.openSnackbar(message);
                  return;
              }
            })
            .finally(() => {
              this.setState({
                performingAction: false,
              });
            });
        }
      );
    }
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  handlePasswordConfirmationChange = (event) => {
    const passwordConfirmation = event.target.value;

    this.setState({
      passwordConfirmation: passwordConfirmation,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    const {
      performingAction,
      emailAddress,
      password,
      passwordConfirmation,
      errors,
    } = this.state;

    if (this.props.user) {
      return (
        <Redirect to='/'/>
      )
    }

    function Copyright() {
      return (
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://www.mameal.co.uk/">
            My Meal
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      );
    }

    return (
      <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <Container fixed>
          <Typography variant="h1" component="h1" className={classes.mainText}>
            COMING
          </Typography>
          <Typography variant="h1" component="h1" gutterBottom className={classes.mainText}>
            SOON...
          </Typography>
        </Container>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              className={classes.text}
              autoComplete="email"
              disabled={performingAction}
              error={!!(errors && errors.emailAddress)}
              fullWidth
              helperText={
                errors && errors.emailAddress ? errors.emailAddress[0] : ""
              }
              label="E-mail address"
              placeholder="john@doe.com"
              required
              type="email"
              value={emailAddress}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={this.handleEmailAddressChange}
            />

            <TextField
              className={classes.text}
              autoComplete="new-password"
              disabled={performingAction}
              error={!!(errors && errors.password)}
              fullWidth
              helperText={
                errors && errors.password ? errors.password[0] : ""
              }
              label="Password"
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              required
              type="password"
              value={password}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={this.handlePasswordChange}
            />

            <TextField
              className={classes.text}
              autoComplete="password"
              disabled={performingAction}
              error={!!(errors && errors.passwordConfirmation)}
              fullWidth
              helperText={
                errors && errors.passwordConfirmation
                  ? errors.passwordConfirmation[0]
                  : ""
              }
              label="Password confirmation"
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              required
              type="password"
              value={passwordConfirmation}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={this.handlePasswordConfirmationChange}
            />

            <Button
              color="primary"
              type="submit"
              fullWidth
              className={classes.submit}
              disabled={
                !emailAddress ||
                !password ||
                !passwordConfirmation ||
                performingAction
              }
              variant="contained"
              onClick={this.signUp}
            >
              Sign up
            </Button>

            <Grid container justify="flex-end">
              <Grid item>
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/signin"
                  underline="none"
                >
                  {"Already have an account? Sign In"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
    );
  }
}

SignUpDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignUpDialog);
