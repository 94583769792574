import React from "react";
import { MDBMask, MDBView, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { makeStyles } from '@material-ui/core/styles';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  text: {
    left: "40",
  align: "right",
  color: "000000",
  },
}));


function HoverPage() {

  const classes = useStyles();

    return (
      <MDBContainer className="mt-5">
        <MDBRow>
          <MDBCol md="4">
            <MDBView hover>
              <img
              height="260"
              width="430"
                src="https://businessfightspoverty.org/wp-content/uploads/2020/10/mesh-shopping-bag-with-green-vegetables-and-yellow-fruits-on-green-picture-id1210954659.jpg"
                className="img-fluid"
              />
              <MDBMask className="flex-center" overlay="green-light">
                <h4 align="left" className="text" >Healthy</h4>
              </MDBMask>
            </MDBView>
          </MDBCol>
          <MDBCol md="4">
            <MDBView hover>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c97.jpeg?alt=media&token=25720ffe-6acb-4c31-85d7-d3a373dbbf6d"
                className="img-fluid"
              />
              <MDBMask className="flex-center" overlay="yellow-light">
              <h4 align="left" className="text" > Plant-based </h4>
              </MDBMask>
            </MDBView>
          </MDBCol>
          <MDBCol md="4">
            <MDBView hover>
              <img
                height="280"
                width="430"
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FDelivery11.png?alt=media&token=5f1561dc-4800-432d-943c-5b6d4845813c"
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="blue-light">
              <h4 align="left" className="text" > Quick </h4>
              </MDBMask>
            </MDBView>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
          <MDBCol md="6">
            <MDBView hover zoom>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FTofu_dragonfruit_vegan_blog_lg.jpeg?alt=media&token=6b8540e0-2678-450a-8e2b-71481a792a55"
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center">
              <h1 align="left" className="text-white"> 2021 </h1>
              </MDBMask>
            </MDBView>
          </MDBCol>
          <MDBCol md="6">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c9a.jpeg?alt=media&token=043188ab-7eaf-4ea1-b105-46139134ec42"
              className="img-fluid rounded-circle hoverable"
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

export default HoverPage;