import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Typography from '../components/Typography';
import { withStyles } from '@material-ui/core/styles';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { motion } from 'framer-motion';
 
const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    overflow: 'hidden',
  },
  image: {
    marginTop: 70,
    height: 207,
    width: 460,
    align: "center",
    position: "absolute",
  },
  kek: {
    marginTop: 3,
    textColor: 'red',
    variant: "h3",
    backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPic1.png?alt=media&token=07720315-c73f-4895-99d0-f067f3e4c09e")`
  },
  subs: {
  color: "#58b55c",
  fontStyle: "italic",
  }
});

function Times(props) {
  const { classes } = props;
  const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };
  return (
    <section className={classes.root}>
    <VerticalTimeline>
     <Typography variant="h3" align="center">
     WHY NOW?
     </Typography>
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(33, 150, 243)', 
      color: '#00000' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      date="6 Reasons the platform is needed in 2021"
      dateClassName="kek"
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    >
     <Typography variant="h6" color="00000" align="center">
     Here's Our Market Research
     </Typography>
            <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c7a.jpeg?alt=media&token=ac5feece-53f9-4a53-8907-4ffdca87f3b7"
              className={classes.image}
            />
    </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      date="I"
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    >
      <h3 align="right">Growing TA</h3>
      <h4 align="right" className={classes.subs}>Vegan & Vegetarian statistics</h4>
      <p align="right">
        By 2021, 12.3 million Brits (18%) will follow a Meat-Free diet
        Searches for “vegan” food grew by 153% and orders by 163%
      </p>
      <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}} 
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c7b.jpeg?alt=media&token=a18451bc-5633-499d-ad29-6d61164aa103"
              className={classes.image}
            />
    </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      date="II"
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    >
      <h3>Rise of Delivery</h3>
      <h4 className={classes.subs}>Food Takeaway / Delivery market</h4>
      <p>
      Restaurant-to-Consumer is 70% of UK Food Delivery segment
      With 20mln users now, the average revenue per each is 180$
      </p>
      <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c7c.jpeg?alt=media&token=6c1d70b2-1293-43ce-b31e-205c87ea43d2"
              className={classes.image}
            />
    </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      date="III"
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    >
      <h3 align="right">Customisation</h3>
      <h4 align="right" className={classes.subs}>Food Personalisation Trend</h4>
      <p align="right">
        90% of Food Apps users would try substitutions in their order 
        Subway, Starbucks, Chipotle & Domino's let Customers create!
      </p>
      <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c7d.jpeg?alt=media&token=1680f8dc-24e6-41b5-9e41-5aa84cb28146"
              className={classes.image}
            />
    </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--education"
      date="IV"
      iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    >
      <h3>Covid-19 Impact</h3>
      <h4 className={classes.subs}>Food Industry revenue fall</h4>
      <p>
       30,000 job losses in Britain, 2020 (163% jump in redundancies) 
       Crucial to make technological improvement in the industry NOW
       </p>
      <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c7f.jpeg?alt=media&token=15f20579-c2d1-413a-b52d-360ca3080a2c"
              className={classes.image}
            />
    </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--education"
      date="V"
      iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    >
      <h3 align="right">Healthy Trends</h3>
      <h4 align="right" className={classes.subs}>Food Habits & wellbeing</h4>
      <p align="right">      
      40% of British adults tend to follow a healthy and balanced diet
      For food Intolerant (17%), Allegic (3%) people - Info is required!
      </p>
      <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c80.jpeg?alt=media&token=ca634c53-aacb-4400-82df-0b82f0fefbea"
              className={classes.image}
            />      
    </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--education"
      date="VI"
      iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    >
      <h3>130 Responces</h3>
      <h4 className={classes.subs}>Our 2021 Survey Results</h4>
      <p>
       60% of people with diets face any problem when ordering food
       45 shared personal concerns / experiences, which are similar
      </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
      iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
      date="Hope you enjoyed reading!"
      marginBottom="20"
    />
  </VerticalTimeline>
  </section>
  );
}

Times.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Times);