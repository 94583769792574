import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import NewCard from '../components/NewCard';
import { motion } from 'framer-motion';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: '100%',
    width: 300,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

const cards = [
  {
    link: 'https://trulyexperiences.com/blog/veganism-uk-statistics/',
    text: 'A record-breaking 560,000 Brits signed up to the 2021 Veganuary campaign.',
    img: 'https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fpic1.jpg?alt=media&token=11329d2a-fa1f-4d68-9230-7479c96e57f2',
  },
  {
    link: 'https://trulyexperiences.com/blog/veganism-uk-statistics/',
    text: 'In the first week of 2021, searches for “vegan” food on Deliveroo grew by 153% from the previous month.',
    img: 'https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fpic2.jpg?alt=media&token=c5c3280c-aa99-4b16-a339-47134a51ade6',
  },
  {
    link: 'https://trulyexperiences.com/blog/veganism-uk-statistics/',
    text: 'Vegans & Vegetarians will make up  25% of the UK  population in 2025.',
    img: 'https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fpic3.jpg?alt=media&token=1b1c97c5-8e09-47c6-a730-399d0bebf4d9',
  },
]

function ProductValuesCopy(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container} >
        <Grid 
          container 
          spacing={5}
          direction="row"
          justify="center"
        >
          <Grid item xs={12}>
          <motion.div whileHover={{scale: 1.2}} whileTap={{ scale: 0.9 }}>
          <Typography variant="h4" marked="center" align="center" component="h2"  className={classes.offsetBottom}>
            Why Vegan?
          </Typography>
          </motion.div>
          </Grid>
          {cards.map(card => (          
          <Grid item xs={12} md={4}>
           <motion.div whileHover={{scale: 1.1}} whileTap={{ scale: 0.9 }}>
           <NewCard 
            text={card.text}
            link={card.link}
            img={card.img}
            />
            </motion.div>
          </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

ProductValuesCopy.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValuesCopy);