import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '../../LandingPage/modules/components/Typography';
import { motion } from 'framer-motion';


const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    align: 'center',
    position: 'relative',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
  },
  image: {
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 900,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  container: {
    alignItems: 'center',
  },
});

function PhotosOne(props) {
  const { classes } = props;


  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={9}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides13.png?alt=media&token=d5c8b2e0-7a76-41ef-97cd-df2891710398"
              alt="Meal Info"
              className={classes.image}
            />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={3}>

        <Typography variant="h6" align="center" >
            -
            </Typography>
            <motion.div whileHover={{scale: 1.1, color: '#248555' }} whileTap={{ scale: 0.9 }}>
        <Typography variant="h4" align="center" >
            Meal Name
            </Typography>
          </motion.div>
          <Typography variant="h6" align="center" >
            -
            </Typography>
          <motion.div whileHover={{scale: 1.1, color: '#ddc656' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="h4" align="center" >
              Price
          </Typography>
          </motion.div>
          <Typography variant="h6" align="center" >
            -
            </Typography>
          <motion.div whileHover={{scale: 1.1, color: '#7f64c1' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="h4" align="center" >
            Ingredients
          </Typography>
          </motion.div>
          <Typography variant="h6" align="center" >
            -
            </Typography>
          <motion.div whileHover={{scale: 1.1, color: '#de8b9f' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="h4" align="center" >
            Allergens
          </Typography>
          </motion.div>
          <Typography variant="h6" align="center" >
            -
            </Typography>
          <motion.div whileHover={{scale: 1.1, color: '#80b8d8' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="h4" align="center" >
            Diets
          </Typography>
          </motion.div>
          <Typography variant="h6" align="center" >
            -
            </Typography>
          <motion.div whileHover={{scale: 1.1, color: '#ddc656' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="h4" align="center" >
            Restaurant
          </Typography>
          </motion.div>
          <Typography variant="h6" align="center" >
            -
            </Typography>

        </Grid>
      </Grid>
    </Container>
  );
}

PhotosOne.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhotosOne);