import withRoot from '../LandingPage/modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import NameKek from './components/NameKek';
import ValuesKek from './components/ValuesKek';
import Slideshow from './components/Slideshow';
import Timeline from './components/Timeline';
import Sliderauto from './components/Sliderauto';
import Transferlist from './components/Transferlist';
import HoverPage from './components/HoverPage';
import AppFooter from '../LandingPage/modules/views/AppFooter'
import Photos from './components/Photos'
import PhotosOne from './components/PhotosOne'

function LandingAboutUs() {
  return (
    <React.Fragment>
      <NameKek/>
      <Timeline/>
      <Slideshow/>
      <PhotosOne/>
      <Photos/>
      <Sliderauto/>
      <HoverPage/>
      <AppFooter/>
    </React.Fragment>
  );
}

export default withRoot(LandingAboutUs);