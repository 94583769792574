import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import { motion, useMotionValue, useTransform, AnimatePresence } from "framer-motion";import { Button } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 70,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  offsetBottom: {
    marginBottom: theme.spacing(3),
  },
});

function Burger(props) {
  const { classes } = props;
  const [ value, setValue ] = useState(0)
  const [ isToggled, setToggle ] = useState(1)
  const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };
  const x = useMotionValue(0);
  const background = useTransform(
    x,
    [-100, 0, 100],
    ["#f4fc03", "#ced41c", "#d4bb1c"]
  );

  return (
    <section className={classes.root}>
 
          <input type="range" min="-100" max="500" value={value}  
          onChange={(e) => setValue(e.target.value)} />
          <AnimatePresence>
          {isToggled && ( 
          <motion.h2 initial={{ opacity: 0}}
          animate={{ opacity: 1, x: value + "px" }}
          exit={{ opacity: 0}} >
            Meow
            </motion.h2>
          )}
          </AnimatePresence>
          <p data-tip="hello world">This is sth</p>
          <ReactTooltip effect="solid" />
          
          <Button onClick={() => setToggle((prevValue) => {
            return prevValue ? 0 : 1;
          })
          }>KEK</Button>
     
                <div
                className="side"
                style={{ transform: "translate3d(5px, 5px, 5px)" }}
                >
                <a data-tip data-for="happyFace">
                 Heyyyy
                </a>
                <ReactTooltip id="happyFace" type="error">
                  <span>Show happy face</span>
                </ReactTooltip>
              </div>

          <motion.div style={{ background }}>
      <motion.div
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        style={{ x }}
      >
        <div x={x}>foo</div>
      </motion.div>
    </motion.div>

            <motion.div initial={{ opacity: 0}} 
      animate={{ opacity: isToggled }}
      transition={{ duration: 4 }}
      drag="x"
      dragConstraints={{ left: -100, right: 100 }}
      className={classes.item}>
              <img
                className={classes.image}
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fp1.png?alt=media&token=b3bd6655-8f75-41e6-bd32-2fb8b68ce15b"
                alt="burger"
              />
            </motion.div>

    </section>
  );
}

Burger.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Burger);