import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import ReactTooltip from 'react-tooltip';

const backgroundImage =
  'https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fwallpaper_4mb.jpg?alt=media&token=f5b5bc21-8581-4c7b-8374-816113a3040f';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: theme.palette.common.black,
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 100,
    marginTop: theme.spacing(2),
    zIndex: 10,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  more: {
    marginTop: theme.spacing(3),
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      
      <Typography color="inherit" align="left" variant="h1">
        
      </Typography>
      <Typography color="inherit" align="left" variant="h1">
        Start your online
      </Typography>
      <Typography color="inherit" align="left" variant="h1">
        Customization today
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.more}>
      </Typography>
      <Typography color="inherit" align="left" variant="h4">
      Join our 2021 Vegan and Vegetarian platform 
      </Typography>
      <Typography color="inherit" align="left" variant="h4" className={classes.h5}>
      for food search, customisation and orders.
      </Typography>
      

      <div
                >
                <a data-tip data-for="Hey">
                <Button
        color="secondary"
        variant="contained"
        size="small"
        className={classes.button}
        component="a"
        href="https://youtu.be/40j7pK0Vfno"
      >
        Watch a Video
      </Button>
                  </a>
                <ReactTooltip id="Hey" type="light" effect="float" place='top'>
                  <span>2-min prototype video</span>
                </ReactTooltip>
              </div>

    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);