import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../LandingPage/modules/components/Button';
import Typography from '../../LandingPage/modules/components/Typography';
import ProductHeroLayout from '../../LandingPage/modules/views/ProductHeroLayout';


const backgroundImage =
  'https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fwallpaper_4mb.jpg?alt=media&token=f5b5bc21-8581-4c7b-8374-816113a3040f';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: theme.palette.common.black,
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 100,
    marginTop: theme.spacing(2),
    zIndex: 10,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  more: {
    marginTop: theme.spacing(3),
  },
});

function NameKek(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src='{backgroundImage}' alt="increase priority" />

      <Typography color="inherit" align="left" variant="h1">
        
      </Typography>
      <Typography color="inherit" align="left" variant="h1">
        About MyMeal 2021
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.more}>
      </Typography>
      <Typography color="inherit" align="left" variant="h4">
      Start your online customisation today 
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        className={classes.button}
        component="a"
        href="https://youtu.be/qCSUoJMpuPo"
      >
        Watch a Video
      </Button>
    </ProductHeroLayout>
  );
}

NameKek.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NameKek);