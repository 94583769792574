import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Bar from "../Bar";
import DialogHost from "../DialogHost";


class HomePage extends Component {

  render() {
    const { 
      performingAction,
      theme,
      user,
      userData,
      roles,
      onAboutClick,
      onSettingsClick,
      onSignOutClick,
      openSnackbar,
      dialogs,
     } = this.props;

    return (
      <Fragment>
        <Bar
          performingAction={performingAction}
          theme={theme}
          user={user}
          userData={userData}
          roles={roles}
          onAboutClick={onAboutClick}
          onSettingsClick={onSettingsClick}
          onSignOutClick={onSignOutClick}
        />

        <DialogHost
          performingAction={performingAction}
          theme={theme}
          user={user}
          userData={userData}
          openSnackbar={openSnackbar}
          dialogs={dialogs}
        />
      </Fragment>
    );
  }

}

HomePage.propTypes = {
  user: PropTypes.object,
};

export default withRouter(HomePage);
