import React, { Component } from "react";
import PropTypes from "prop-types";
import validate from "validate.js";
import { withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Paper,
  Avatar,
  Container,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  Link,
} from "@material-ui/core";
import constraints from "../../data/constraints";
import authentication from "../../services/authentication";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Redirect, Link as RouterLink } from "react-router-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ParticleComponent from "../LandingPage/modules/views/ParticleComponent";

const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/examples%2Fsignin.jpg?alt=media&token=a849eb86-1b66-4d90-bbd7-4c31d31a2a0c)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mainText: {
    color: 'white',
    fontWeight: 'bold',
  },
  container: {
    justifyContent: 'center', 
    flex:1,
    alignItems: 'center',
  },
});


const initialState = {
  performingAction: false,
  emailAddress: "",
  password: "",
  errors: null,
};


class SignInDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  getSignInButton = (classes) => {
    const { emailAddress, password, performingAction } = this.state;

    if (emailAddress && !password) {
      return (
        <Button
          type="submit"
          fullWidth
          color="primary"
          disabled={!emailAddress || performingAction}
          variant="contained"
          className={classes.submit}
          onClick={() => this.sendSignInLinkToEmail()}
        >
          Send sign-in link
        </Button>
      );
    }

    return (
      <Button
        type="submit"
        fullWidth
        color="primary"
        disabled={!emailAddress || performingAction}
        variant="contained"
        className={classes.submit}
        onClick={() => this.signIn()}
      >
        Sign in
      </Button>
    );
  };

  resetPassword = () => {
    const { emailAddress } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          errors: null,
        },
        () => {
          this.setState(
            {
              performingAction: true,
            },
            () => {
              authentication
                .resetPassword(emailAddress)
                .then((value) => {
                  this.props.openSnackbar(
                    `Sent password reset e-mail to ${emailAddress}`
                  );
                })
                .catch((reason) => {
                  const code = reason.code;
                  const message = reason.message;

                  switch (code) {
                    case "auth/invalid-email":
                    case "auth/missing-android-pkg-name":
                    case "auth/missing-continue-uri":
                    case "auth/missing-ios-bundle-id":
                    case "auth/invalid-continue-uri":
                    case "auth/unauthorized-continue-uri":
                    case "auth/user-not-found":
                      this.props.openSnackbar(message);
                      return;

                    default:
                      this.props.openSnackbar(message);
                      return;
                  }
                })
                .finally(() => {
                  this.setState({
                    performingAction: false,
                  });
                });
            }
          );
        }
      );
    }
  };

  signIn = () => {
    const { emailAddress, password } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress,
        password: password,
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
          errors: null,
        },
        () => {
          authentication
            .signIn(emailAddress, password)
            .then((user) => {
              this.props.openSnackbar(
                `Signed in as ${user.displayName || emailAddress}`
              );
            })
            .catch((reason) => {
              const code = reason.code;
              const message = reason.message;

              switch (code) {
                case "auth/invalid-email":
                case "auth/user-disabled":
                case "auth/user-not-found":
                case "auth/wrong-password":
                  this.props.openSnackbar(message);
                  return;

                default:
                  this.props.openSnackbar(message);
                  return;
              }
            })
            .finally(() => {
              this.setState({
                performingAction: false,
              });
            });
        }
      );
    }
  };

  sendSignInLinkToEmail = () => {
    const { emailAddress } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        performingAction: true,
        errors: null,
      },
      () => {
        authentication
          .sendSignInLinkToEmail(emailAddress)
          .then(() => {
            this.props.dialogProps.onClose(() => {
              this.props.openSnackbar(`Sent sign-in e-mail to ${emailAddress}`);
            });
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              case "auth/argument-error":
              case "auth/invalid-email":
              case "auth/missing-android-pkg-name":
              case "auth/missing-continue-uri":
              case "auth/missing-ios-bundle-id":
              case "auth/invalid-continue-uri":
              case "auth/unauthorized-continue-uri":
                this.props.openSnackbar(message);
                return;

              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  signInWithAuthProvider = (provider) => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .signInWithAuthProvider(provider)
          .then((user) => {
            this.props.dialogProps.onClose(() => {
              const displayName = user.displayName;
              const emailAddress = user.email;

              this.props.openSnackbar(
                `Signed in as ${displayName || emailAddress}`
              );
            });
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              case "auth/account-exists-with-different-credential":
              case "auth/auth-domain-config-required":
              case "auth/cancelled-popup-request":
              case "auth/operation-not-allowed":
              case "auth/operation-not-supported-in-this-environment":
              case "auth/popup-blocked":
              case "auth/popup-closed-by-user":
              case "auth/unauthorized-domain":
                this.props.openSnackbar(message);
                return;

              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;
    const { performingAction, emailAddress, password, errors } = this.state;

    if (this.props.user) {
      return (
        <Redirect to='/'/>
      )
    }

    function Particles() {

      return (
        <Router>
          <div
            style={{
              position: "relative",
              top: 0,
              left: 500,
              width: "100%",
              height: "100%"
            }}
          >
            <ParticleComponent />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            >
              {/* You can render <Route> and <NavTabs /> here */}
            </div>
          </div>
        </Router>
      );
    }


    function Copyright() {
      return (
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://www.mameal.co.uk/">
            MyMeal
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      );
    }

    
    return (
      <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
      <Container position="absolute" className={classes.container}>
          <Typography variant="h1" component="h1" className={classes.mainText}>
            WELCOME
          </Typography>
          <Typography variant="h1" component="h1" gutterBottom className={classes.mainText}>
            BACK
          </Typography>
        </Container>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              className={classes.text}
              autoComplete="email"
              disabled={performingAction}
              error={!!(errors && errors.emailAddress)}
              fullWidth
              helperText={
                errors && errors.emailAddress ? errors.emailAddress[0] : ""
              }
              label="E-mail address"
              placeholder="john@doe.com"
              required
              type="email"
              value={emailAddress}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={this.handleEmailAddressChange}
            />
            <TextField
              className={classes.text}
              autoComplete="current-password"
              disabled={performingAction}
              error={!!(errors && errors.password)}
              fullWidth
              helperText={
                errors && errors.password ? errors.password[0] : ""
              }
              label="Password"
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              required
              type="password"
              value={password}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={this.handlePasswordChange}
            />


            {this.getSignInButton(classes)}
            <Grid container>
              <Grid item xs>
              {!(!emailAddress || performingAction) &&
                <Link 
                  variant="body2"
                  component="button"
                  color="primary"
                  onClick={this.resetPassword}
                >
                  Reset password
                </Link>}
              </Grid>
              <Grid item>
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/signup"
                  underline="none"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
    );
  }
}

SignInDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignInDialog);
