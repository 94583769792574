import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Typography from '../../LandingPage/modules/components/Typography';
import { withStyles } from '@material-ui/core/styles';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { motion } from 'framer-motion';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  image: {
    marginTop: 50,
    height: 207,
    width: 460,
    align: "center",
    position: "absolute",
  },
  images: {
    marginTop: 70,
    height: 65,
    width: 230,
    align: "center",
    position: "absolute",
  },
  kek: {
    marginTop: 5,
    textColor: 'red',
    variant: "h3",
    backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPic1.png?alt=media&token=07720315-c73f-4895-99d0-f067f3e4c09e")`
  },
});

function Timeline(props) {
  const { classes } = props;
  const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };
  return (
    <section className={classes.root}>
    <VerticalTimeline>
     <Typography marginTop="3" variant="h3" align="center">
     How to join?
     </Typography>
     <VerticalTimelineElement
      contentStyle={{ background: 'rgb(16, 204, 82)', 
      variant: "p",
      color: '#00000' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(16, 204, 82)' }}
      iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
      date=" "
      marginBottom="20">
        <Typography variant="h6" align="center">
     Sign Up
     </Typography>
        <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c80.jpeg?alt=media&token=ca634c53-aacb-4400-82df-0b82f0fefbea"
              className={classes.image}
            />    
  </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(33, 150, 243)', 
      variant: "h6",
      color: '#00000' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      dateClassName="kek"
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    >
     <Typography variant="h6" align="center">
     Fill out the Form sent shortly after
     </Typography>
            <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c7c.jpeg?alt=media&token=6c1d70b2-1293-43ce-b31e-205c87ea43d2"
              className={classes.image}
            />
    </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(33, 150, 243)', 
      variant: "h3",
      color: '#00000' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    >
      <Typography variant="h6" align="center">
     Verify meal customisation and prices
     </Typography>
      <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}} 
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c7b.jpeg?alt=media&token=a18451bc-5633-499d-ad29-6d61164aa103"
              className={classes.image}
            />
    </VerticalTimelineElement>
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(233, 30, 99)', 
      variant: "h3",
      color: '#00000' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
      iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    >
      <Typography variant="h6" align="center">
      State delivery option / update "Profile"
     </Typography>
      <div className={classes.image} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c7a.jpeg?alt=media&token=ac5feece-53f9-4a53-8907-4ffdca87f3b7"
              className={classes.image}
            />
    </VerticalTimelineElement>
     <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(233, 30, 99)', 
      variant: "h3",
      color: '#00000' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
      iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    >
      <Typography variant="h6" align="center">
     Receive orders after the launch
     </Typography> 
     <div className={classes.images} />
            <motion.img whileHover={{scale: 1.4}}
              src="https://lh3.googleusercontent.com/proxy/dTaDfM8cZeeOYg0h_ILhL8Wf-UNGuKVoMiM3pOREIrx0YnashnR4eOVPmOpMXKXDI_ULeetmi5gKYn4s8sNJamR5YARXiTaXwmltyyajxcSb_q9u"
              className={classes.images}
            />
    </VerticalTimelineElement>
    <VerticalTimelineElement
      iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
      marginBottom="20"
    />
  </VerticalTimeline>
  </section>
  );
}

Timeline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Timeline);