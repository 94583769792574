import React from 'react';
import Carousel from 'nuka-carousel';

export default class extends React.Component {
  render() {
    return (
      <Carousel>
        <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbottom1.png?alt=media&token=51575748-5dba-4f95-8f8e-cd315fb9fa0c" />
        <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbottom1.png?alt=media&token=51575748-5dba-4f95-8f8e-cd315fb9fa0c" />
        <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbottom1.png?alt=media&token=51575748-5dba-4f95-8f8e-cd315fb9fa0c" />
        <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbottom1.png?alt=media&token=51575748-5dba-4f95-8f8e-cd315fb9fa0c" />
        <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbottom1.png?alt=media&token=51575748-5dba-4f95-8f8e-cd315fb9fa0c" />
        <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fbottom1.png?alt=media&token=51575748-5dba-4f95-8f8e-cd315fb9fa0c" />
      </Carousel>
    );
  }
}