import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { MDBMask, MDBView, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import { motion } from 'framer-motion';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="https://www.mameal.co.uk/">
        MyMeal 
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}


function SimpleDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">+7 (778) 349-45-39 </DialogTitle>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  iconSize: {
    width: 70,
    height: "100%",
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 135,
    height: 35,
  },
}));

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
];

export default function AppFooter() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
      <SimpleDialog open={open} onClose={handleClose} />
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={2}>
            <Grid
              container
              direction="column"
              justify="flex-end"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item className={classes.icons}>
                <a href="mailto: madina27k@gmail.com" className={classes.icon}>
                <MDBView hover zoom>
                <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Femail.png?alt=media&token=6bf8b424-fded-4d0e-a25d-b80e4e15b346" 
                  alt="email" align="center"
                  className={classes.iconSize}
                  />
                  </MDBView>
                  </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="h6" marked="left" gutterBottom>
              Legal
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link href="/terms">Terms</Link>
              </li>
              <li className={classes.listItem}>
                <Link href="/privacy">Privacy</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
          <div>
          <motion.img whileHover={{scale: 1.25}} transition={transition}
          src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FAvoCadoO.png?alt=media&token=60e022eb-0ef7-497e-ae4b-4550732b4f5f"
          align="center" width="185" height="120"
          />
         </div>
            </Grid>
          <Grid item xs={6} sm={8} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Language
            </Typography>
            <TextField
              select
              SelectProps={{
                native: true,
              }}
              className={classes.language}
            >
              {LANGUAGES.map((language) => (
                <option value={language.code} key={language.code}>
                  {language.name}
                </option>
              ))}
            </TextField>
          </Grid>
            <Grid item xs={6} sm={4} md={2}>
            <Grid
              container
              direction="column"
              justify="flex-end"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item className={classes.icons}>
              <Link onClick={handleClickOpen} className={classes.icon}>
                 <MDBView hover zoom>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fphone.png?alt=media&token=78307aa0-1007-4b6b-a773-3d2ac1e3ef22" 
                  alt="Call" align="right"
                  width="80" height="100%"
                  />
                  </MDBView>
              </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {'Icons made by '}
              <Link href="https://www.freepik.com" rel="sponsored" title="Freepik">
                Freepik
              </Link>
              {' from '}
              <Link href="https://www.flaticon.com" rel="sponsored" title="Flaticon">
                www.flaticon.com
              </Link>
              {' is licensed by '}
              <Link
                href="https://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </Link>
            </Typography>
          </Grid>
           <Grid item>
              <Copyright align="right"/>
            </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}