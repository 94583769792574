import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../../LandingPage/modules/components/Typography';

class DemoCarousel extends Component {
    render() {
        return (
            <Container component="section">
      <Grid container>
      <Grid item xs={12} >
          <Typography variant="h4" marked="center" align="center" marginBottom="3" component="h2">
            Business Outline
          </Typography>
        </Grid>
      <Grid item xs={2} >
         <div>
          <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3c9e.jpeg?alt=media&token=33f9aa53-a66e-4073-bf30-d21e6b222013"
          align="left" top="50" width="180" height="450"
          />
         </div>
      </Grid>
        <Grid item xs={8} >
            <Carousel autoPlay interval="5000" transitionTime="500" dynamicHeight="true" >
                <div>
                    <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides1.png?alt=media&token=8a160e73-2a7d-46c5-bbde-deec6a4f0a13" />
                    <p className="legend">MyMeal</p>
                </div>
                <div>
                   <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides2.png?alt=media&token=4868c304-ffbd-4333-94a9-e826ef13801f" />
                    <p className="legend">Product</p>
                </div>
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides3.png?alt=media&token=b735f40a-bef5-4034-8b79-7cf2ee53a4e8" />
                    <p className="legend">Market</p>
                </div>
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides4.png?alt=media&token=38df5e43-7536-4934-b171-14825ff5486a" />
                    <p className="legend">Competition</p>
                </div>
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides5.png?alt=media&token=66fbf12e-a793-4249-b6b8-2b258dc1851b" />
                    <p className="legend">Innovation</p>
                </div>
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides6.png?alt=media&token=cf8750ca-10f4-4a6a-b736-6480169e954b" />
                    <p className="legend">Roadmap</p>
                </div>
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides7.png?alt=media&token=b9bedaa2-7497-41ac-ae05-90812c9bfff1" />
                    <p className="legend">Revenue</p>
                </div>
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides8.png?alt=media&token=5302aa9e-0bad-488a-a68c-4f26b5027b38" />
                    <p className="legend">Costs</p>
                </div>
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides9.png?alt=media&token=d7e9c7e7-0610-4dfb-aba5-a591714c4c54" />
                    <p className="legend">Founders</p>
                </div>
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides10.png?alt=media&token=8cb6e3e6-0f5c-4e0f-be58-7135449ad937" />
                    <p className="legend">BMC</p>
                </div>
            </Carousel>
            </Grid>
            <Grid item xs={2} >
         <div>
          <img src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffullsizeoutput_3ca4.jpeg?alt=media&token=f9390942-beed-4a70-898f-a667eeafba9e"
          align="right" top="50" width="180" height="450"
          />
         </div>
      </Grid>
            </Grid>
          </Container>
        );
    }
};

export default DemoCarousel;