import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import { MDBMask, MDBView, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import { motion } from 'framer-motion';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(0),
  },
  image: {
    height: 180,
    width: 290,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  offsetBottom: {
    marginBottom: theme.spacing(0),
  },
  link: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

function Valueskeks(props) {
  const { classes } = props;
  const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
        <motion.div whileHover={{scale: 1.1}} transition={transition} >
          <Typography variant="h4" marked="center" align="center" component="h2"  className={classes.offsetBottom}>
         Why is MyMeal Innovative?
          </Typography>
          </motion.div>
        </Grid>
          <Grid item xs={12} md={4}>
            <motion.div className={classes.item} whileHover={{scale: 1.1}} whileTap={{ scale: 0.9 }} transition={transition}>
             <MDBView hover>
              <img
                className={classes.image}
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fvegan1.jpg?alt=media&token=32b9af9d-d139-4d76-959c-3d3ba97b407f"
                alt="vegan1"
              />
                <MDBMask className="flex-center" overlay="stylish-light">
                <h5 className="white-text">Plant-based</h5>
              </MDBMask>
            </MDBView>
              <Typography variant="h6" align="center">
               . . .
              </Typography>
              <Typography variant="h5" align="center">
               First meat-free delivery-offering platform designed for meeting
              </Typography>
              <Typography variant="h5" align="center">
               dietary requirements in the UK
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={4}>
          <motion.div className={classes.item} whileHover={{scale: 1.1}} whileTap={{ scale: 0.9 }} transition={transition}>
             <MDBView hover>
              <img
                className={classes.image}
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fvegan2.jpeg?alt=media&token=6ae17d1f-fa68-43ec-ba98-b99b0b46a5aa"
                alt="vegan2"
              />
              <MDBMask className="flex-center" overlay="stylish-light">
              <h5 className="white-text">Customisable</h5>
              </MDBMask>
            </MDBView>
              <Typography variant="h6" align="center">
               . . .
              </Typography>
              <Typography variant="h5" paragraph align="center">
              An entirely customisable generic solution for restaurants. 3 out of 4 customers expect customisation when ordering food (Tectomic)
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={4}>
          <motion.div className={classes.item} whileHover={{scale: 1.1}} whileTap={{ scale: 0.9 }} transition={transition}>
              <MDBView hover>
              <img
                className={classes.image}
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fvegan3.jpg?alt=media&token=037c829e-cd56-4002-9017-ba770e38c99a"
                alt="vegan3"
              />
                <MDBMask className="flex-center" overlay="stylish-light">
                <h5 className="white-text">Menu search</h5>
                </MDBMask>
            </MDBView>
               <Typography variant="h6" align="center">
               . . .
              </Typography>
              <Typography variant="h5" paragraph align="center">
              Restaurants’ menu analysis based on the nutritional value, customer choice, flavours, and ingredients
                </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={1}>
          <img align="center" height="70" width="83" 
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FDomino%E2%80%99s-Logo.png?alt=media&token=53241694-67bd-409f-84b4-ee8e1502cb17"
              />
          </Grid>
          <Grid item xs={8}>
          <Typography variant="h5" align="center" color="#81c784" >
          - Domino's & Subway customisation Apps are a huge success -
          </Typography>
          <Typography variant="h6" align="center" className={classes.link}>
          Why not make a change in your own business?
        </Typography>
          </Grid>
          <Grid item xs={2}>
          <img align="center" height="60" width="45" 
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSubway_logo.png?alt=media&token=93857499-10f8-4521-bf04-afe231208280"
              />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Valueskeks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Valueskeks);