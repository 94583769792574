import React from 'react'
import Container from '@material-ui/core/Container';
import Slider from "react-slick";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 2:1
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  titlee: {
    color: '#81c784',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
     logo: {
      fontSize: 34,
      fontFamily: 'ArialMT',
      fontWeight: '400',
      fontStyle: 'normal',
      fontDisplay: 'swap',
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Segoe UI',
      'bold',
    ].join(','),
  },});

function SimpleSlider() {

  const classes = useStyles();
  const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };


  var settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
  };
  return (
    <Container className={classes.root} component="section">
      <Grid container>
      <Grid item xs={12} md={12}>
        
               <div
                className="side"
                style={{ transform: "translate3d(5px, 5px, 5px)" }}
                >
                <a data-tip data-for="Here we are">
                <motion.div whileHover={{scale: 1.2}} transition={transition} >
                <Typography variant="h4" marked="center" align="center" className={classes.title} component="h2">
                 Our Product
                </Typography>
                </motion.div>
                  </a>
                <ReactTooltip id="Here we are" type="success" effect="float" place='top'>
                  <span>Show happy face</span>
                </ReactTooltip>
              </div>
        </Grid>
      <Grid item xs={12} md={7}>
     <Slider {...settings}>
      <div>
        <MediaCard url="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPic1.png?alt=media&token=07720315-c73f-4895-99d0-f067f3e4c09e"/>
      </div>
      <div>
        <MediaCard url="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture2.png?alt=media&token=8820165b-505d-4810-b4ce-166479673200"/>
      </div>
      <div>
      <MediaCard url="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture3.png?alt=media&token=24aacabf-baf0-4074-b23a-58a75d64302f"/>
      </div>
      <div>
      <MediaCard url="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPiccture4.png?alt=media&token=db5d3de1-e5c9-4e99-93c0-1436f825d279"/>
      </div>
      <div>
      <MediaCard url="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture5.png?alt=media&token=9f2fc6ab-5ba5-41ed-8d23-9ea49efbf5f1"/>
      </div>
      <div>
      <MediaCard url="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture6.png?alt=media&token=5a1dfadd-9bb5-4bff-9ce8-9b859c57e7c0"/>
      </div>
      <div>
      <MediaCard url="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture7.png?alt=media&token=b001f98c-8983-462b-83e9-0a763b65a5f6"/>
      </div>
      <div>
      <MediaCard url="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture8.png?alt=media&token=78d10114-6084-46be-a4d9-c985a638966b"/>
      </div>
     </Slider>
     </Grid>
      <Grid item xs={12} md={5}>
 <div className="App">
   <div>
   <motion.div whileHover={{scale: 1.1, color: '#248555' }} whileTap={{ scale: 0.9 }}>
           <Typography variant="subtitle1" align="center" className={classes.logo}>
            Database of UK restaurants
            </Typography>
          </motion.div>
          <motion.div whileHover={{scale: 1.1, color: '#ddc656' }} whileTap={{ scale: 0.9 }}>
           <Typography  variant="subtitle1" align="center" className={classes.logo}>
              Selection of menu items
          </Typography>
          </motion.div>
          <motion.div whileHover={{scale: 1.1, color: '#7f64c1' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="subtitle1" align="center" className={classes.logo}>
            Elaborate data on each meal
          </Typography>
          </motion.div>
          <motion.div whileHover={{scale: 1.1, color: '#de8b9f' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="subtitle1" align="center" className={classes.logo}>
            Customise your own food
          </Typography>
          </motion.div>
          <motion.div whileHover={{scale: 1.1, color: '#80b8d8' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="subtitle1" align="center" className={classes.logo}>
            Online ordering / takeaways
          </Typography>
          </motion.div>
          <motion.div whileHover={{scale: 1.1, color: '#ddc656' }} whileTap={{ scale: 0.9 }}>
          <Typography variant="subtitle1" align="center" className={classes.logo}>
            Personalised Feed & Diet
          </Typography>
          </motion.div>
     <Typography variant="h3" align="center" className={classes.titlee}>
     100% MEAT-FREE
     </Typography>
   </div>
 </div>
        </Grid>
         </Grid>
    </Container>
  );
}


function MediaCard(props) {
  const classes = useStyles();

  const {url} = props;

  return (
    <Card>
        <CardMedia
          className={classes.media}
          image={url}
          title="Contemplative Reptile"
        />
    </Card>
  );
}

export default SimpleSlider;