import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '../../LandingPage/modules/components/Typography';


const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    display: 'flex',
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
  },
  image: {
    right: 0,
    bottom: 0,
    height: '300',
    width: '100%',
    maxWidth: 800,
  },
  title: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
  container: {
    alignItems: 'center',
  },
});

function Photos(props) {
  const { classes } = props;


  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} >
          <Typography variant="h4" marked="center" align="center" className={classes.title} component="h2">
            Our Values
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides11.png?alt=media&token=a1e52349-bdfc-43e3-b0aa-333721eda86e"
              alt="B2C"
              className={classes.image}
            />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FSlides12.png?alt=media&token=eca6f70b-0d48-45dd-a581-07a494115214"
              alt="B2B"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
}

Photos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Photos);