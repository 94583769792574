import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Typography from '@material-ui/core/Typography';
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import Grid from '@material-ui/core/Grid';
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { bugs, website, server } from "variables/general.js";
import ProductHero from 'components/LandingPage/modules/views/ProductHero';
import Carousel from 'nuka-carousel';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";


const useStyles = makeStyles(styles);

var cardStyle = {
  display: 'block',
  width: '25vw',
  transitionDuration: '0.3s',
  height: '30vw'
}

export default function Dashboard1() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
      <Grid item xs={12}>
      <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Neat Burger
        </Typography>
        </Grid>
      <Grid item xs={6} sm={8} md={8}>
       <Carousel>
         <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fneat%20burger.jpg?alt=media&token=8c6e2c96-dee7-444e-832f-faefec393a85"
          width="200" 
          />
         <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fneatburger3.jpg?alt=media&token=bf917031-b4fa-489e-829f-eee3ffd85059"
          width="200" 
          />
          <img border={1} borderColor="black" src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fneatburger2.jpg?alt=media&token=0f3b8bc6-81ee-455d-85b2-9ddbcc785013"
          width="200" 
          />
       </Carousel>
        </Grid>
       <GridItem xs={6} sm={4} md={4}>
       <Box color="black" border={1} borderColor="black" fontSize={25} textAlign="center" bgcolor="#5499C7" p={1}> 
         See Our Menu 
       </Box> 
       <Box color="black" border={1} borderColor="black" fontSize={18} textAlign="center" bgcolor="#5499C7" p={1}> 
         Call  |  Message  |  Order 
       </Box> 
       <Box color="black" border={1} borderColor="black" fontSize={16} marginTop={1} bgcolor="#FDEBD0" p={1} textAlign="left"> 
       <div>4 Princes Street Mayfair,<br />London W1B 2LE England</div>
       </Box> 
       <Box color="black" border={1} borderColor="black" fontSize={16} marginTop={1} bgcolor="#FDEBD0" p={1} textAlign="right"> 
       Closed now   (see times)
       </Box> 
       <Box color="black" border={1} borderColor="black" marginTop={1} bgcolor="#FDEBD0" p={1} fontSize={16} textAlign="left"> 
       Neat-burger.com
       </Box> 
       <Box color="black" border={1} borderColor="black" fontSize={16} marginTop={1} bgcolor="#FDEBD0" p={1} textAlign="right"> 
       Deliveroo.co.uk
       </Box> 
       <Box color="black" border={1} borderColor="black" marginTop={1} bgcolor="#FDEBD0" p={1} fontSize={16} textAlign="center"> 
       Photo Gallery
       </Box> 
       </GridItem>
        <GridItem xs={12} sm={4} md={4}>
        <Card style={{flex:1, backgroundColor:"#5499C7"}}>
          <CardHeader color="danger" stats icon>
            <img height={40} width={40} src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ffacebook-icon-png-black.png?alt=media&token=ba29305c-04a8-4b0c-9feb-352070f1a4a8"
          />
              <p textAlign="right" className={classes.cardCategory}>3762 likes </p>
              <h3 textAlign="right" className={classes.cardTitle}>3,985 followers</h3>
              </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                www.facebook.com
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
        <Card style={{flex:1, backgroundColor:"#5499C7"}}>
            <CardHeader color="danger" stats icon>
            <img height={40} width={40} src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Finsta.png?alt=media&token=f28519bd-e7b9-4835-8782-3c81c7feb3ef"
          />
              <p className={classes.cardCategory}>296 posts</p>
              <h3 marginBottom={1} className={classes.cardTitle}>38.1k followers</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                www.instagram.com
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
        <Card style={{flex:1, backgroundColor:"#5499C7"}}>
            <CardHeader color="info" stats icon>
            <img height={40} width={40} src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Ftwitter%20png.png?alt=media&token=80ae864d-0a33-4eb7-88fc-b734f111d760"
          />
              <p className={classes.cardCategory}>111 tweets</p>
              <h3 className={classes.cardTitle}>2,346k followers</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                www.twitter.com
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
        <Card style={{flex:1, backgroundColor:"#FDEBD0"}}>
          <CardHeader color="danger" stats icon>
            <img height={40} width={40} src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fr1.png?alt=media&token=f289e936-767f-4352-83d6-6fef8c712192"
          />
              <h3 className={classes.cardTitle}>48 Reviews  (4.5/5)</h3>
              </CardHeader>
            <CardFooter stats>
              <div FontSize={17} textAlign="center" color="grey">
                www.tripadvisor.com
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
        <Card style={{flex:1, backgroundColor:"#FDEBD0"}}>
            <CardHeader color="danger" stats icon>
            <img height={40} width={40} src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fr2.png?alt=media&token=295e39dd-90e8-409f-9175-c0fd0421189a"
          />
              <h3 marginBottom={1} className={classes.cardTitle}>431 Reviews  (4.3/5)</h3>
            </CardHeader>
            <CardFooter stats>
              <div FontSize={17} >
                www.google.com
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
        <Card style={{flex:1, backgroundColor:"#FDEBD0"}}>
            <CardHeader color="info" stats icon>
            <img height={40} width={40} src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fr3.png?alt=media&token=f22217ad-df54-42ca-adc3-85155b68be76"
          />
              <h3 className={classes.cardTitle}>75 Reviews  (4.5/5)</h3>
            </CardHeader>
            <CardFooter stats>
              <div FontSize={17}>
                www.happycow.net
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6} sm={8} md={8}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Bugs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                )
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                )
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                )
              }
            ]}
          />
        </GridItem>
        <GridItem xs={6} sm={4} md={4}>
        <Card style={cardStyle}>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["Name", "Salary", "Country"]}
                tableData={[
                  ["Dakota Rice jjjjjjjjjjj"],
                  ["Minerva", "$23,789", "Curaçao"],
                  ["Sage", "$56,142", "Netherlands"],
                  ["Philip", "$38,735", "Korea, South"]
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  
  );
}
