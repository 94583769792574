import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 70,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  offsetBottom: {
    marginBottom: theme.spacing(3),
  },
});

function ProductValues(props) {
  const { classes } = props;
  const [ value, setValue ] = useState(0)
  const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };


  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <div>
          <Typography variant="h4"
           marked="center" align="center" component="h2"  className={classes.offsetBottom}>
            What is my meal?
            </Typography>
          </div>
          <Typography variant="h5" align="center">
          Online tool for searching, customising & ordering healthy meals
          </Typography>
          <Typography variant="h5" align="center">
          from UK restaurants and helping to maintain your special diet :)
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <div
      className={classes.item}>
              <div>
                <a data-tip data-for="Burger">
              <motion.img whileHover={{scale: 1.5}} transition={transition}
                className={classes.image}
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fp1.png?alt=media&token=b3bd6655-8f75-41e6-bd32-2fb8b68ce15b"
                alt="burger"
              />
              </a>
                <ReactTooltip id="Burger" type="light" effect="float" place='top'>
                  <span>Build</span>
                </ReactTooltip>
              </div>
              <Typography variant="h6" className={classes.title}>
               food customisation service
              </Typography>
              <Typography variant="h5" paragraph>
                {'Order a build-your-own meal or a customised item from the menu. '}
              </Typography>
              <Typography variant="h5">
                {'Ready for eat in, take away and delivery via third-party services.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>

            <div>
                <a data-tip data-for="search">
                <motion.img whileHover={{scale: 1.5}} transition={transition}
                className={classes.image}
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fp2.png?alt=media&token=1f13693e-7b7a-4ad8-8d7f-3612e919e3f6"
                alt="search"
              />
              </a>
                <ReactTooltip id="search" type="light" effect="float" place='top'>
                  <span>Browse</span>
                </ReactTooltip>
              </div>
              <Typography variant="h6" className={classes.title}>
                Browsable food database
              </Typography>
              <Typography variant="h5" paragraph>
                {'Explore and find specific meals served at your local restaurants. '}
              </Typography>
              <Typography variant="h5">
                {'Compared by price, ingredients, deliveries, nutritional value, etc.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>

            <div>
                <a data-tip data-for="Person">
                <motion.img whileHover={{scale: 1.5}} transition={transition}
                className={classes.image}
                src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fp3.png?alt=media&token=209776f2-15fa-4139-8010-8d9dc56e566f"
                alt="person"
              />
              </a>
                <ReactTooltip id="Person" type="light" effect="float" place='top'>
                  <span>Bond</span>
                </ReactTooltip>
              </div>

              <Typography variant="h6" className={classes.title}>
                Meat-free social network 
              </Typography>
              <Typography variant="h5" paragraph>
                {'Place for sharing and promoting experiences, ideas and brands. '}
                </Typography>
              <Typography variant="h5">
                {'Content feed, direct messaging with businesses & communities.'}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);