import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';
import { motion } from 'framer-motion';

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 7),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 100,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(2),
    height: 12,
  },
});

function ProductHowItWorks(props) {
  const { classes } = props;
  const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

  return (
    <section className={classes.root}>
      <Container className={classes.container} >
       <motion.div whileHover={{scale: 1.2}} transition={transition} >
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          How it works
        </Typography>
        </motion.div>
        <div>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>1.</div>
                <motion.div whileHover={{scale: 1.2}} whileTap={{ scale: 0.9 }} transition={transition}>
                  <img
                  src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fpic1.1.png?alt=media&token=5a086505-91a8-4dc2-a330-895c81528e63"
                  alt="signup"
                  className={classes.image}
                />
                </motion.div>
                <Typography variant="h6" align="center">
                Sign up as a
                </Typography>
                <Typography variant="h6" align="center">
                Business Account
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>2.</div>
                <motion.div whileHover={{scale: 1.2}} whileTap={{ scale: 0.9 }} transition={transition}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fpic1.2.png?alt=media&token=acf31a12-f544-4832-b6b6-b296977b95cb"
                  alt="form"
                  className={classes.image}
                />
                </motion.div>
                <Typography variant="h6" align="center">
                Fill in the form
                </Typography>
                <Typography variant="h6" align="center">
                we will send 
                </Typography>
                <Typography variant="h6" align="center">
                to you shortly 
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>3.</div>
                <motion.div whileHover={{scale: 1.2}} whileTap={{ scale: 0.9 }} transition={transition}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2Fpic1.3.png?alt=media&token=b023c43e-6a34-4f1c-91f8-b3b8b13b050c"
                  alt="orders"
                  className={classes.image}
                />
                </motion.div>
                <Typography variant="h6" align="center">
                Receive orders
                </Typography>
                <Typography variant="h6" align="center">
                after the launch
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="medium"
          variant="contained"
          className={classes.button}
          component="a"
          href="/signup"
        >
          Get started
        </Button>
      </Container>
    </section>
  );
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);