import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import ProductValuesCopy from './modules/views/ProductValuesCopy';
import Fade from './modules/views/Fade';
import Valueskeks from './modules/views/Valueskeks';
import Times from './modules/views/Times';

//import Fadee from './modules/views/Fadee';
//import { Scroll } from "framer-motion"


const rootElement = document.getElementById("root");

function LandingPage() {
  return (
    <React.Fragment>
       <ProductHero />
        <ProductValues />
            <div background-size="auto"
  background-repeat="no-repeat"
  background-position="center"
      style={{
         backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FLeaves4.jpeg?alt=media&token=f53a613c-c9d2-4c10-9d0a-24564493dcf0")`
      }}>
      <ProductCategories />
      </div>
      <ProductHowItWorks />
      <Fade />
      <ProductValuesCopy />
      <div background-size="auto"
  background-repeat="no-repeat"
  background-position="center"
      style={{
         backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FLeaves4.jpeg?alt=media&token=f53a613c-c9d2-4c10-9d0a-24564493dcf0")`
      }}>
      <Times />
      </div>
      <Valueskeks />
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(LandingPage);