import React, { Component } from "react";
import Slider from "react-slick";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../../LandingPage/modules/components/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8),
      backgroundColor: theme.palette.secondary.light,
    },
    imageDots: {
        width: '300',
      },
      image: {
        width: '100%',
        height: '100',
      },
      title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
}));

function Sliderauto() {

    const classes = useStyles();
  
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2500,
        cssEase: "linear",
    };
    return (
      <div className={classes.root}>
          <Typography variant="h4" marked="center" align="center" className={classes.title} component="h2">
            Our Product
          </Typography>
        <Slider {...settings}>
        <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPic1.png?alt=media&token=07720315-c73f-4895-99d0-f067f3e4c09e"
              alt="1"
              className={classes.image}
            />
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture2.png?alt=media&token=8820165b-505d-4810-b4ce-166479673200"
              alt="2"
              className={classes.image}
            />
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture3.png?alt=media&token=24aacabf-baf0-4074-b23a-58a75d64302f"
              alt="3"
              className={classes.image}
            />
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPiccture4.png?alt=media&token=db5d3de1-e5c9-4e99-93c0-1436f825d279"
              alt="4"
              className={classes.image}
            />
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture5.png?alt=media&token=9f2fc6ab-5ba5-41ed-8d23-9ea49efbf5f1"
              alt="5"
              className={classes.image}
            />
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture6.png?alt=media&token=5a1dfadd-9bb5-4bff-9ce8-9b859c57e7c0"
              alt="6"
              className={classes.image}
            />
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture7.png?alt=media&token=b001f98c-8983-462b-83e9-0a763b65a5f6"
              alt="7"
              className={classes.image}
            />
            <div className={classes.imageDots} />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/my-meal-uk.appspot.com/o/Landing%20page%2FPicture8.png?alt=media&token=78d10114-6084-46be-a4d9-c985a638966b"
              alt="8"
              className={classes.image}
            />
        </Slider>
      </div>
    );
}

export default Sliderauto;