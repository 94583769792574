import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import HomePage from "../HomePage";
import LandingPage from "../LandingPage";
import UserPage from "../../UserPage";
import NotFoundPage from "../NotFoundPage";
import SignUpDialog from "../SignUpDialog";
import SignInDialog from "../SignInDialog";
import Terms from "../LandingPage/Terms";
import Privacy from "../LandingPage/Privacy";
import AboutUs from "../AboutUs";
import LandingHome from "../LandingPage/LandingHome";
import AppAppBar from '../LandingPage/modules/views/AppAppBar';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";

const hist = createBrowserHistory();


class BrowserRouter extends Component {
  render() {
    const {user} = this.props;


    return (
      <Router history={hist}>

        <Switch>
          <Route path="/" exact>
            {user ? (
              <Fragment>
                <HomePage {...this.props} />
                <LandingHome />
              </Fragment>
            ) : (
              <Fragment>
                <AppAppBar />
                <LandingPage />
              </Fragment>
            )}
          </Route>

          <Route path="/signup" exact>
            <SignUpDialog {...this.props} />
          </Route>

          <Route path="/signin" exact>
            <SignInDialog {...this.props} />
          </Route>

          <Route path="/terms" exact>
          {user ? (
              <Fragment>
                <HomePage {...this.props} />
                <Terms />
              </Fragment>
            ) : (
              <Fragment>
                <AppAppBar />
                <Terms />
              </Fragment>
            )}
          </Route>

          <Route path="/aboutus" exact>
          {user ? (
              <Fragment>
                <HomePage {...this.props} />
                <AboutUs />
              </Fragment>
            ) : (
              <Fragment>
                <AppAppBar />
                <AboutUs />
              </Fragment>
            )}
          </Route>

          <Route path="/privacy" exact>
            {user ? (
              <Fragment>
                <HomePage {...this.props} />
                <Privacy />
              </Fragment>
            ) : (
              <Fragment>
                <AppAppBar />
                <Privacy />
              </Fragment>
            )}
          </Route>

          <Route path="/admin" component={Admin}/>
          
          <Route path="/rtl" component={RTL}/>


          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
    );
  }
}

BrowserRouter.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default BrowserRouter;
