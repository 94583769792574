import withRoot from 'components/LandingPage/modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductHero from 'components/LandingPage/modules/views/ProductHero';
import Dashboard1 from 'views/Dashboard/Dashboard1'
import Dashboard2 from 'views/Dashboard/Dashboard2'


function Dashboard() {
  return (
    <React.Fragment>
      <Dashboard1 />
      <Dashboard2 />
      <ProductHero />
    </React.Fragment>
  );
}

export default withRoot(Dashboard);